#bar-chart-container-horizontal {
  border: 1px solid rgb(0, 0, 0);
  /* border-left: 1.4px solid rgba(128, 128, 128, 0.497); */
  /* position: relative; */

  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#bar-chart-horizontal {
  /* border: 1px solid purple; */
  padding: 0 2.6vw;
  /* margin: 0 4vw auto; */
  height: 100%;
  width: 100%;
}

.chart-horizontal {
  /* border: 1px solid orange; */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  /* padding: 2vw; */
  width: 60vw;
  overflow:hidden
}

@media screen and (max-width: 859px) {
  #bar-chart-container-horizontal {
    /* border: 1px solid green; */
    width: 90%;
    padding: 0;
  }
  .chart-horizontal {
    margin-top: 4vw;
    padding: 4vw 0;
    width: 100vw;
    border-bottom: 1.4px solid rgba(128, 128, 128, 0.497);
    border-top: 1.4px solid rgba(128, 128, 128, 0.497);
    /* height: 100vw; */
  }
  #bar-chart-horizontal {
    width: 100%;
    height: 100%;
  }
}
