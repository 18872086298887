#gh-section-container{
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-start;
  margin-left: 3vw;

}

#gh-content{
  /* border: 1px solid green; */
  width: 70vw;
  min-height: 200px;
  height: 100%;
}

#gh-loading{
  border: 1px solid rgba(111, 111, 111, 0.463);
  background-color: rgba(111, 111, 111, 0.463);
  height: 30vw;
  width: 57.5%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 860px) {
  #gh-section-container {
    justify-content: center;
    /* margin-top: 14vw; */
    margin-left: 0;
  }

  #gh-content {
    height: auto;
    width: 100%;
  }
}
