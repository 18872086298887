#radar-chart-section {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 4vw 1.5vw;
  /* border: 1px solid purple; */
  width: 38vw;
}

#radar-chart-container {
  display: flex;
  justify-content: center;
  width: 40vw;
  height: 40vw;
  border: 1px solid rgb(0, 0, 0);
}

#chart-raw-metrics-container {
  /* border: 1px solid green; */
  box-sizing: border-box;
  padding: 4vw 4vw 4vw 0;
  font-size: 1.2vw;
  display: none;
  height: 75vw;
  justify-content: space-between;
  font-family: "adaptive-mono", monospace;
  letter-spacing: 1.2px;
  text-shadow: 0 0 0.5px rgb(255 255 255 / 57%), 0 0 1px rgb(255 255 255 / 60%), 0 0 1px rgb(255 255 255 / 60%), 0 0 2px rgb(255 255 255 / 53%), 0 0 2px rgb(255 255 255);
}

#chart-raw-metrics {
  display: flex;
  flex-direction: column;
  font-size: 1.2vw;
  /* justify-content: space-between; */
  /* border: 1px solid green; */
  /* width: 11vw; */
  /* padding-bottom: 1vw; */
}
#metric-lang-num-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  width: fit-content;
  /* border: 1px solid blue; */
}

#metric-number {
  margin-bottom: .8vw;
}

@media screen and (max-width: 860px) {
  #radar-chart-section {
    height: 75vw;
    /* border: 1px solid; */
    width: 100vw;
    padding: 0;
  }
  #radar-chart-container {
    box-sizing: border-box;
    padding: 4vw;
    /* border: 1px solid orange; */
    width: 75vw;
    height: 75vw;
  }
  #chart-raw-metrics {
  /* border: 1px solid green; */
    font-size: 2.2vw;
    /* width: 25vw; */
    /* width: 100%; */
  }
  #metric-lang-num-container {
    display: flex;
    height: 100vw;
    flex-direction: column;
  }
  #chart-raw-metrics-container {
    display: flex;
  }
}
