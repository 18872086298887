.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 40px 0;
}

.timeline-container::after {
  background-color: #adadad84;
  content: '';
  position: absolute;
  left: calc(50% - 2px);
  width: 2px;
  height: 100%;
}
