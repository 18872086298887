#homepage-text {
  /* height: 100%;
  width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}

#homepage-bocodes {
  font-size: 13vw;
}

#homepage-name {
  font-family: "adaptive-mono", monospace;
  letter-spacing: 1.2px;
  font-size: 1.4vw;
}

#github-container {
  /* border: 1px solid orange; */
}
