#navbar-container {
  /* border: 1px solid purple; */
  width: 100%;
}

.navbar-option {
  text-decoration: none;
  color: white;
  /* WHITE CURSOR */
  cursor: url("../../img/cursors/Cross.cur"), auto;
  /* font-size: 1.2vw; */
}

#list-div {
  display: flex;
  justify-content: space-between;
  /* padding: .5vw 4vw; */
  /* border: 1px solid green; */
  width: 100%;
}

#navbar-list {
  height: 1vw;
  width: 36vw;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid orange; */
  margin-right: 40px;
}

 li {
  font-size: 1vw;
  font-family: "adaptive-mono", monospace;
  letter-spacing: 1.2px;
  list-style-type: none;
  text-shadow:
      0 0 .5px rgba(255, 255, 255, 0.575),
      0 0 1px rgba(255, 255, 255, 0.599),
      0 0 1px rgba(255, 255, 255, 0.6),
      0 0 2px rgba(255, 255, 255, 0.526),
      0 0 2px rgb(255, 255, 255);
}

#navbar-list li:hover {
  color: white;
  z-index: 3;
  opacity: 67%;
  filter: blur(.4px);
  text-shadow:
      0 0 8px rgba(255, 255, 255, 0.519),
      0 0 18px rgba(255, 255, 255, 0.583),
      0 0 28px rgba(255, 255, 255, 0.555),
      0 0 28px rgba(255, 255, 255, 0.434),
      0 0 32px rgb(255, 255, 255);
  /* cursor: pointer; */
}
#navbar-home {
  opacity: 65%;
}

#navbar-home:hover {
  z-index: 3;
  opacity: 66%;
  filter: blur(.4px);
  text-shadow:
    0 0 8px rgba(255, 255, 255, 0.745),
    0 0 8px rgba(255, 255, 255, 0.583),
    0 0 8px rgba(255, 255, 255, 0.677),
    0 0 8px rgba(255, 255, 255, 0.741),
    0 0 12px rgb(255, 255, 255);
}


@media screen and (min-width: 749px) {
  li{
    font-size: 1.6vw;
  }
}

@media screen and (min-width: 1200px) {
  li{
    font-size: 1.2vw;
  }
}

@media screen and (max-width: 750px) {
  #navbar-list {
    width:40vw;
    /* justify-content: space-between; */
    /* border: 1px solid blue; */
  }

  #list-div {
    width: 100%;
  }

  li {
    font-size: 2vw;
  }
}
