#projects-page-container {
  /* border: 1px solid orange; */
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}

#project-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(390px, 400px));
  /* border: 1px solid blue; */
  justify-content: center;
  width: 80%;
  height: 100%;
  margin-top: 40px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

#indiv-project {
  /* border: 1px solid pink; */
  display: flex;
  justify-content: center;
  background-color: #6464647f;
  /* border: 1px solid #6464643c; */
  width: 390px;
  text-decoration: none;
}

#indiv-project:hover {
  /* transition: all .15s ease-in-out; */
  /* transform: translateY(-8px); */
  background-color: #84848495;
  cursor: pointer;
}

#project-container {
  color: white;
  /* border: 1px solid blue; */
  width: fit-content;
}

#project-content {
  padding: 20px;
}

#project-name-and-comp {
  /* border: 1px solid orange; */
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

#project-name {
  /* border: 1px solid green; */
  color: rgba(255, 255, 255, 0.737);
  font-family: 'IBM Plex Mono', monospace;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

#project-company {
  color: white;
  background-color: rgb(19, 18, 77);
  font-family: "adaptive-mono", monospace;
  letter-spacing: 1.2px;
  padding: 4px;
}

#project-img {
  width: 330px;
}

@media screen and (max-width: 860px) {
  #projects-page-container {
    /* width: 100vw; */
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
}
