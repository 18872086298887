#screen {
  /* height: 40vw; */
  height: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  /* border: 1px solid blue; */
  /* animation: glitch 0.15s infinite; */
  /* GREEN CURSOR */
  cursor: url("../../img/cursors/CrosshairNormal.cur"), auto;
}

#screen:hover {
  /* cursor: default; */
  cursor: url("../../img/cursors/CrosshairNormal.cur"), auto;
}

.screen-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-shadow:
      0 0 5px rgba(255, 255, 255, 0.519),
      0 0 6px rgba(255, 255, 255, 0.583),
      0 0 6px rgba(255, 255, 255, 0.555),
      0 0 6px rgba(255, 255, 255, 0.434),
      0 0 11px rgb(255, 255, 255);
  /* border: 1px solid greenyellow; */
  margin-bottom: 10px;
}

#screen-text:hover {
  /* cursor: default; */
  cursor: url("../../img/cursors/CrosshairNormal.cur"), auto;
}

/* flicker */
#screen::after {
  position: absolute;
  z-index: 2;
  /* height: 40vw; */
  height: 30vw;
  width: 100%;
  /* top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
  display: block;
  animation: flicker 0.15s infinite;
  background: rgba(18, 16, 16, 0.1);
  content: " ";
  opacity: 0;
  pointer-events: none;
}

/* Scanlines */
#screen::before {
  position: absolute;
  z-index: 2;
  /* height: 40vw; */
  height: 30vw;
  width: 100%;
  /* top: 0;
  right: 0;
  bottom: 0; */
  left: 0;
  display: block;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%),
    linear-gradient(to right, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06)),
    radial-gradient(ellipse at center,
      rgba(141, 141, 141, 0.3) 0%,
      rgba(224, 224, 224, 0.26) 58%,
      rgba(228, 228, 228, 0.16) 80%,
      rgba(19, 94, 29, 0.08) 93%,
      rgba(10, 23, 12, 0) 100%);
  /* rgba(27, 212, 89, 0.3) 0%,
      rgba(27, 212, 89, 0.26) 58%,
      rgba(21, 235, 92, 0.16) 80%,
      rgba(19, 94, 29, 0.08) 93%,
      rgba(10, 23, 12, 0) 100%); */
  background-size: 100% 3px, 5px 100%;
  box-shadow: inset 0 0 20vh 20vh rgba(0, 0, 0, 0.3);
  content: " ";
  pointer-events: none;
}

@keyframes glitch {
  0% {
    transform: scale(1, 1.02);
  }

  2% {
    transform: scale(1, 1.01);
  }

  4% {
    transform: scale(1.01, 1);
  }

  6% {
    transform: scale(1, 1);
  }
}

@keyframes flicker {
  0% {
    opacity: 0.27861;
  }

  5% {
    opacity: 0.34769;
  }

  10% {
    opacity: 0.23604;
  }

  15% {
    opacity: 0.90626;
  }

  20% {
    opacity: 0.18128;
  }

  25% {
    opacity: 0.83891;
  }

  30% {
    opacity: 0.65583;
  }

  35% {
    opacity: 0.67807;
  }

  40% {
    opacity: 0.26559;
  }

  45% {
    opacity: 0.84693;
  }

  50% {
    opacity: 0.96019;
  }

  55% {
    opacity: 0.08594;
  }

  60% {
    opacity: 0.20313;
  }

  65% {
    opacity: 0.71988;
  }

  70% {
    opacity: 0.53455;
  }

  75% {
    opacity: 0.37288;
  }

  80% {
    opacity: 0.71428;
  }

  85% {
    opacity: 0.70419;
  }

  90% {
    opacity: 0.7003;
  }

  95% {
    opacity: 0.36108;
  }

  100% {
    opacity: 0.24387;
  }
}

@keyframes text-shadow {
  0% {
    text-shadow: 0.4389924193300864px 0 1px rgba(0, 30, 255, 0.5), -0.4389924193300864px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  5% {
    text-shadow: 2.7928974010788217px 0 1px rgba(0, 30, 255, 0.5), -2.7928974010788217px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  10% {
    text-shadow: 0.02956275843481219px 0 1px rgba(0, 30, 255, 0.5), -0.02956275843481219px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  15% {
    text-shadow: 0.40218538552878136px 0 1px rgba(0, 30, 255, 0.5), -0.40218538552878136px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  20% {
    text-shadow: 3.4794037899852017px 0 1px rgba(0, 30, 255, 0.5), -3.4794037899852017px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  25% {
    text-shadow: 1.6125630401149584px 0 1px rgba(0, 30, 255, 0.5), -1.6125630401149584px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  30% {
    text-shadow: 0.7015590085143956px 0 1px rgba(0, 30, 255, 0.5), -0.7015590085143956px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  35% {
    text-shadow: 3.896914047650351px 0 1px rgba(0, 30, 255, 0.5), -3.896914047650351px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  40% {
    text-shadow: 3.870905614848819px 0 1px rgba(0, 30, 255, 0.5), -3.870905614848819px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  45% {
    text-shadow: 2.231056963361899px 0 1px rgba(0, 30, 255, 0.5), -2.231056963361899px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  50% {
    text-shadow: 0.08084290417898504px 0 1px rgba(0, 30, 255, 0.5), -0.08084290417898504px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  55% {
    text-shadow: 2.3758461067427543px 0 1px rgba(0, 30, 255, 0.5), -2.3758461067427543px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  60% {
    text-shadow: 2.202193051050636px 0 1px rgba(0, 30, 255, 0.5), -2.202193051050636px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  65% {
    text-shadow: 2.8638780614874975px 0 1px rgba(0, 30, 255, 0.5), -2.8638780614874975px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  70% {
    text-shadow: 0.48874025155497314px 0 1px rgba(0, 30, 255, 0.5), -0.48874025155497314px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  75% {
    text-shadow: 1.8948491305757957px 0 1px rgba(0, 30, 255, 0.5), -1.8948491305757957px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  80% {
    text-shadow: 0.0833037308038857px 0 1px rgba(0, 30, 255, 0.5), -0.0833037308038857px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  85% {
    text-shadow: 0.09769827255241735px 0 1px rgba(0, 30, 255, 0.5), -0.09769827255241735px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  90% {
    text-shadow: 3.443339761481782px 0 1px rgba(0, 30, 255, 0.5), -3.443339761481782px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  95% {
    text-shadow: 2.1841838852799786px 0 1px rgba(0, 30, 255, 0.5), -2.1841838852799786px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }

  100% {
    text-shadow: 2.6208764473832513px 0 1px rgba(0, 30, 255, 0.5), -2.6208764473832513px 0 1px rgba(255, 0, 80, 0.3),
      0 0 3px;
  }
}

/* STATIC CODE */
.static {
  height: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  background-image: repeating-radial-gradient(circle at 30% 30%, white, black 0.00035px);
  animation: tv-static 5s linear infinite;
  filter: blur(.5px);
  box-shadow:
      0 0 55px rgba(255, 255, 255, 0.318),
      0 0 56px rgba(255, 255, 255, 0.275),
      0 0 56px rgba(255, 255, 255, 0.253),
      0 0 56px rgba(255, 255, 255, 0.276),
      0 0 51px rgba(255, 255, 255, 0.311);
      cursor: url("../../img/cursors/CrosshairNormal.cur"), auto;
}

/* STATIC CODE */
.altstatic {
  height: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  background-image: url("../../img/homepage/static.gif");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(.5px);
  cursor: url("../../img/cursors/CrosshairNormal.cur"), auto;
}

@keyframes tv-static {
  from {
    background-size: 18% 18%;
  }

  to {
    background-size: 15% 15%;
  }
}


/* SCREEN-TEXT */
#screen-text {
  height: 100%;
  width: 100%;
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}

#screen-bobo-title {
  font-size: 17vw;
}

#screen-page-title {
  font-size: 14vw;
}

/* GREEN CHANNEL TEXT */
/* #channel-text {
  position: absolute;
  top: .7vw;
  right: 2.6vw;
  font-size: 3vw;
  color: rgb(109, 255, 47);
  text-shadow:
      0 0 5px rgba(95, 253, 81, 0.914),
      0 0 6px rgba(95, 253, 81, 0.914),
      0 0 6px rgba(95, 253, 81, 0.773),
      0 0 6px rgba(95, 253, 81, 0.745),
      0 0 11px rgb(255, 255, 255);
} */

/* WHITE CHANNEL TEXT */
#channel-text {
  position: absolute;
  top: -1vw;
  right: 2.6vw;
  font-size: 3.9vw;
  color: rgb(251, 251, 251);
  text-shadow:
      0 0 5px rgba(255, 255, 255, 0.771),
      0 0 4px rgba(255, 255, 255, 0.812),
      0 0 4px rgba(255, 255, 255, 0.816),
      0 0 4px rgba(255, 255, 255, 0.751),
      0 0 6px rgb(255, 255, 255);
}

@media screen and (max-width: 475px) {
  #screen {
    height: 40vw;
  }

  .static {
    height: 40vw;
  }

  .altstatic {
    height: 40vw;
  }

  #screen::before {
    height: 40vw;
  }

  #screen::after {
    height: 40vw;
  }
}

@media screen and (max-width: 750px) {
  #channel-text {
    top: -31px;
    font-size: 3vw;
  }
}
