#contactpage-text {
  /* height: 100%;
  width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}

#contactpage-experience {
  font-size: 12vw;
}
