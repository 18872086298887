#bar-chart-container {
  border: 1px solid blue;
  border-left: 1.4px solid rgba(128, 128, 128, 0.497);
  /* position: relative; */
  padding-left: 1.4vw;
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#bar-chart {
  /* margin: 0 4vw auto; */
  height: 100%;
  width: 100%;
}

.chart {
  width: 44.5vw;
  overflow:hidden
}

@media screen and (max-width: 860px) {
  #bar-chart-container {
    width: 100vw;
    padding: 0;
    border-left: none;
  }
  .chart {
    /* border: 1px solid orange; */
    border-top: 1.4px solid rgba(128, 128, 128, 0.497);
    margin-top: 4vw;
    padding-top: 4vw;
    width: 100%;
    /* height: 100vw; */
  }
  #bar-chart {
    width: 100vw;
    height: 100vw;
  }
}
