#skills-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 5vw));
  grid-gap: 2vw;
  width: 86vw;
  /* border: 1px solid orange; */
  justify-content: center;
}

#indiv-skill {
  display: flex;
  justify-content: center;
  /* border: 1px solid green; */
}

#indiv-skill:hover {
  opacity: 75%;
}

.indiv-skill {
  width: 50px;
  height: auto;
}

#skills-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  /* justify-content: center; */
  /* border: 1px solid blue; */
  /* width: 80%; */
}

#skills-title {
  display: flex;
  justify-content: flex-end;
  width: 70vw;
  /* border: 1px solid purple; */
  margin-bottom: 3vw;
  font-size: 2.4vw;
  font-family: "adaptive-mono", monospace;
  letter-spacing: 1.2px;
  text-shadow:
    0 0 .5px rgba(255, 255, 255, 0.575),
    0 0 1px rgba(255, 255, 255, 0.599),
    0 0 1px rgba(255, 255, 255, 0.6),
    0 0 2px rgba(255, 255, 255, 0.526),
    0 0 2px rgb(255, 255, 255);
}

@media screen and (max-width: 860px) {
  #skills-list {

    grid-gap: 1.6;
  }

  .indiv-skill {
    width: 6.4vw;
    margin-bottom: 4vw;
  }

  #skills-title {
    justify-content: flex-start;
    width: 68vw;
    /* padding-left: 10vw; */
    margin-bottom: 4.2vw;
    font-size: 3.4vw;
  }
}

@media screen and (max-width: 640px) {
  #skills-list {
    width: 96vw;
  }

  .indiv-skill {
    width: 40px;
    margin-bottom: 4vw;
  }

  #skills-title {
    font-size: 4.3vw;
    margin-bottom: 4.8vw;
  }
}

@media screen and (max-width: 475px) {
  #skills-list {
    width: 98vw;
  }
}
