#repos-section {
  box-sizing: border-box;
  /* border: 1px solid purple; */
  width: 35vw;
  /* height: 100%; */
  padding: 2vw 3vw;
  /* padding-left: 2vw; */
}

#repo-list-container {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-top: 2.4vw; */
  margin: 0;
  /* width: 100%; */
  height: 80%;
  padding: 0;
}

#currently-working-on {
  /* border: 1px solid seagreen; */
  height: 20%;
  font-size: 1.5vw;
}

#repo-link-container {
  /* padding-bottom: 1.1vw; */
  /* border: 1px solid blue; */
}

#repo-link-container:hover {
  opacity: 75%;
}

#repo-link {
  color: white;
  text-decoration: none;
  font-size: 1.3vw;
  width: 100%;
  /* border: 1px solid skyblue; */
}

#repo-link-and-languages-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid green; */
  width: 100%;
}

#repo-link-and-languages-container:hover {
  cursor: pointer;
}

#languages-container {
  display: flex;
}

#individual-language {
  margin-left: .36vw;
}

@media screen and (min-width: 860px) {
  #repo-link {
    font-size: 1.6vw;
  }
}

@media screen and (max-width: 859px) {
  #repos-section {
    /* padding: 2vw 6vw 6vw 6vw; */
    margin-left: 0;
    /* margin-top: 2vw; */
  }

  #radar-chart-container {
    width: 100%;
  }

  /* #currently-working-on {
    font-size: 3.2vw;
  } */

  /* #repo-link {
    font-size: 2.4vw;
  } */

  /* #repo-link-container {
    margin-bottom: 1.6vw;
  } */

  #currently-working-on {
    font-size: 3.4vw;
  }

  #repo-link {
    font-size: 2.7vw;
  }

  /* #repo-link-container {
    margin-bottom: 2.6vw;
    width: 70vw;
  } */

  /* #repo-link-container {
    margin-bottom: 1.6vw;
  } */

  #repos-section {
    /* border: 1px solid orange; */
    width: 100vw;
  }

  #repo-list-container {
    width: 100%;
  }

  #repo-link-container {
    margin-bottom: 2.6vw;
    width: 100%;
  }

  #individual-language {
    margin-left: .6vw;
  }
}

@media screen and (max-width: 490px) {
  #currently-working-on {
    font-size: 3vw;
    margin-bottom: 6vw;
  }

  #repo-link {
    font-size: 3vw;
    /* margin-bottom: 1vw; */
  }

  #repo-link-container {
    margin-bottom: 2.6vw;
    width: 97%;
  }

  #repos-section {
    width: 100vw;
    padding: 6vw;
  }

  #repo-list-container {
    width: 100%;
  }
}
